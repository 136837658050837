import React from "react";
import { Select } from "./generic/Select";

export const DiscomfortLevel = () => {
  return (
    <Select
      name="discomfortLevel"
      label="Discomfort Level"
      placeholder="Select discomfort level"
      options={["None", "Mild", "Moderate", "Severe", "Intense"]}
      isRequired
    />
  );
};
