import { gql } from "@apollo/client";

export const GET_PARTNER_BY_SUBDOMAIN = gql`
  query getPartnerBySubdomain($subdomain: String!) {
    getPartnerBySubdomain(subdomain: $subdomain) {
      subdomain
      providerId
      widgetColor
      widgetLogo
      apiKey
      category
    }
  }
`;

export const GET_ISSUES = gql`
  query getIssues {
    getIssues {
      issues {
        ID
        Name
      }
    }
  }
`;

export const getWellaHealthPharmacies = gql`
  query getPharmaciesFromWellaHealth($lga: String, $state: String!) {
    getPharmaciesFromWellaHealth(state: $state, lga: $lga) {
      data {
        pharmacyCode
        pharmacyName
        state
        lga
        area
        address
      }
    }
  }
`;

export const getConsultations = gql`
  query getConsultations(
    $patientId: String
    $orderBy: String
    $page: Int
    $first: Int
  ) {
    getConsultations(
      filterBy: { patient: $patientId }
      orderBy: $orderBy
      page: $page
      first: $first
    ) {
      data {
        _id
        patient
        consultationOwner
        symptoms {
          name
        }
        description
        discomfortLevel
        firstNotice
        doctor
        consultationDuration
        diagnosis {
          ailment
          severity
        }
        doctorNote
        declineReason
        createdAt
        updatedAt
        patientData
        doctorData
        referralId
        providerId
      }
      pageInfo {
        totalDocs
        limit
        offset
        hasPrevPage
        hasNextPage
        page
        totalPages
        pagingCounter
        prevPage
        nextPage
      }
    }
  }
`;

export const findProfiles = gql`
  query findProfiles($hmoId: String, $orderBy: String, $page: Int) {
    profiles(filterBy: { hmoId: $hmoId }, orderBy: $orderBy, page: $page) {
      #... FilterBy, orderBy and page are filter options,search by anything
      data {
        _id
        firstName
        lastName
        height
        weight
        bloodGroup
        genotype
        gender
        phoneNumber
        provider
        plan
        status
        consultations
        createdAt
        image
        rating
        pastIllness {
          id
        }
        providerId
        accountData {
          email
        }
      }
      pageInfo {
        totalDocs
        limit
        offset
        hasPrevPage
        hasNextPage
        page
        totalPages
        pagingCounter
        prevPage
        nextPage
      }
    }
  }
`;

export const getHospitals = gql`
  query getUserTypeProviders($hospitalId: String, $name: String) {
    getUserTypeProviders(
      filterBy: { userTypeId: $hospitalId, name: $name }
      page: -1
    ) {
      provider {
        _id
        name
        icon
        userTypeId
        userCount
        doctorCount
        enrolleeCount
        partnerCount
        createdAt
        updatedAt
        userTypeData {
          name
          icon
          createdAt
          updatedAt
        }
      }
      pageInfo {
        totalDocs
        limit
        offset
        hasPrevPage
        hasNextPage
        page
        totalPages
        pagingCounter
        prevPage
        nextPage
      }
    }
  }
`;

export const getServerTime = gql`
  query getServerTime {
    getServerTime {
      time
      timeLagos
      timeJSdate
      timeLagosJsDate
      hourMin
      hourMinLagos
    }
  }
`;
