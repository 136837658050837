import axios from "axios";
import { useAppState } from "../../contexts/appContext";

export const useFetchAPI = () => {
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const appState = useAppState();
  const token = appState?.partnerInfo?.apiKey;

  const base = axios.create({
    baseURL: BASE_URL + "rest/",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Api-Key ${token}`,
      Accept: "application/json",
    },
  });

  const GET_PLANS = (providerId, apiKey) =>
    base.get(`plans?filterBy[provider]=${providerId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Api-Key ${apiKey}`,
        Accept: "application/json",
      },
    });

  const GET_HMO_USER = async (providerId, hmoId, apiKey) =>
    await axios.get(
      `${
        process.env.REACT_APP_API_BASE_URL
      }rest/enrollees?filterBy[providerId]=${providerId}&filterBy[hmoId]=${hmoId.toUpperCase()}&page=1`,
      {
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Api-Key ${apiKey}`,
        },
      }
    );

  const CREATE_CONSULTATION = async (apiKey, data) => {
    return await fetch(`${BASE_URL}rest/consultations/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Api-Key ${apiKey}`,
      },
      body: JSON.stringify(data),
    });
  };

  const UPDATE_USER_INFO = async (userData, userId) => {
    return base.patch(`enrollees/${userId}`, userData);
  };

  const GET_CONSULTATION_INFO = async (providerId, email) => {
    return base.get(
      `consultations?page=1&first=5&orderBy=-createdAt&providerId=${providerId}&email=${email}&status=completed`
    );
  };

  const GET_ENROLLEE_ID = async (providerId, phone) => {
    try {
      const response = await base.post(`enrollees/send-sms`, {
        providerId,
        phone,
      });
      return response.data;
    } catch (error) {
      console.error("Error sending SMS:", error);
      throw error;
    }
  };

  const GET_HOSPITAL_DOCTORS = async (providerId, date) => {
    return base.get(`doctors?date=${date}&filterBy[providerId]=${providerId}`);
  };

  const GET_DOCTOR_AVAILABILITY = async (date, doctorId) => {
    return base.get(
      `doctors/availibility-for-date?date=${date}&doctor=${doctorId}`
    );
  };

  // const GET_HMO_USER_EXTERNAL = async (providerId, hmoId, apiKey) =>
  //   await axios.post(
  //     `${process.env.REACT_APP_API_BASE_URL}rest/enrollees/search`,
  //     {
  //       providerId,
  //       hmoId: `${hmoId}`.toLowerCase(),
  //     },
  //     {
  //       headers: {
  //         "Content-Type": "applications/json",
  //         Authorization: `Api-Key ${apiKey}`,
  //       },
  //     }
  //   );

  const GET_HMO_USER_EXTERNAL = async (providerId, hmoId, apiKey) => {
    const data = {
      providerId,
      hmoId: `${hmoId}`.toLowerCase(),
    };
    return await fetch(`${BASE_URL}rest/enrollees/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Api-Key ${apiKey}`,
      },
      body: JSON.stringify(data),
    });
  };

  async function fetchData(promise) {
    const response = await promise;

    // Check if the response is OK (status 200-299)
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the response as JSON
    const data = await response.json();

    return data; // Return the parsed data
  }

  return {
    fetchData,
    GET_PLANS,
    GET_HMO_USER,
    GET_ENROLLEE_ID,
    UPDATE_USER_INFO,
    CREATE_CONSULTATION,
    GET_HMO_USER_EXTERNAL,
    GET_HOSPITAL_DOCTORS,
    GET_CONSULTATION_INFO,
    GET_DOCTOR_AVAILABILITY,
  };
};
