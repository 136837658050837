import React, { useMemo, useState } from "react";
import { Loading } from "../Loading";
import { BackButton } from "../buttons";
import { NoData } from "../cards/NoData";
import { useFormContext } from "react-hook-form";
import { useFetchAPI } from "../../api/axios/axios";
import { useQuery as useReactQuery } from "@tanstack/react-query";
import { normalizeEmail, updateFollowUpForm } from "../../utils/funcs";
import { useAppDispatch, useAppState } from "../../contexts/appContext";
import { useFormDispatch, useFormState } from "../../contexts/formContext";
import { PreviousConsultationCard } from "../cards/PreviousConsultationCard";
import { appReducerTypes, formReducerTypes, steps } from "../../utils/mockData";

export const SelectDoctor = () => {
  const appState = useAppState();
  const formState = useFormState();
  const prevConsultation = formState?.selectedPrevConsultation || {};
  const dispatch = useAppDispatch();
  const { setValue } = useFormContext();
  const formDispatch = useFormDispatch();
  const [error, setError] = useState(undefined);
  const { GET_CONSULTATION_INFO } = useFetchAPI();
  const { email } = formState?.userData || {};
  const { widgetColor, providerId } = appState?.partnerInfo || {};
  const [selectedPrevConsultationId, setSelectedPrevConsultationId] = useState(
    prevConsultation?._id
  );

  const {
    data: prevConsultationsData,
    isLoading,
    error: consultationError,
  } = useReactQuery({
    queryKey: ["prevConsultations", providerId, email],
    queryFn: async () =>
      await GET_CONSULTATION_INFO(providerId, normalizeEmail(email)),
    enabled: !!providerId && !!email,
  });

  const prevConsultations = useMemo(() => {
    const prev = prevConsultationsData?.data?.data;
    if (!prev) return null;
    return prev;
  }, [prevConsultationsData]);

  return (
    <div>
      {isLoading ? (
        <Loading color={widgetColor} />
      ) : prevConsultations?.length < 1 ? (
        <div>
          <BackButton step={steps.INTRO_FORM} />
          <NoData
            widgetColor={widgetColor}
            message="No Previous Doctors"
            info="You can create a new consultation by clicking <br /> the button below"
            btnText="Start New Consultation"
            handleClick={() => {
              dispatch({
                type: appReducerTypes?.CHANGE_FORM_STEP,
                payload: { step: steps.CREATE },
              });
            }}
          />
        </div>
      ) : consultationError ? (
        <div>
          <BackButton step={steps.INTRO_FORM} />
          <p>{`Error: ${consultationError?.message}`}</p>
        </div>
      ) : (
        <form className="max-w-[570px]">
          <BackButton step={steps.INTRO_FORM} />
          <div>
            <div className="space-y-7">
              <div>
                <h4 className="text-xl text-gray-400">
                  Previous Consultation(s)
                </h4>
                <p>Select a consultation and click "Continue"</p>
              </div>
              <div className="space-y-5 !mb-5">
                {(prevConsultations || []).map((prev, idx) => {
                  return (
                    <PreviousConsultationCard
                      key={`${idx}_${prev?._id}`}
                      widgetColor={widgetColor}
                      active={selectedPrevConsultationId === prev?._id}
                      setActive={() => {
                        setError(undefined);
                        setSelectedPrevConsultationId(prev?._id);
                        formDispatch({
                          type: formReducerTypes.ADD_SEL_PREV_CONSULT,
                          payload: { selected: prev },
                        });
                      }}
                      prevConsultationInfo={prev}
                    />
                  );
                })}
                <div>
                  <p className="!text-red-500 !font-medium !my-1">{error}</p>
                  <button
                    type="button"
                    style={{ backgroundColor: widgetColor }}
                    className=" cursor-pointer"
                    onClick={() => {
                      if (!selectedPrevConsultationId) {
                        setError(
                          "Please select a previous consultation to continue."
                        );
                        return;
                      }

                      updateFollowUpForm(setValue, prevConsultation);
                      dispatch({
                        type: appReducerTypes?.CHANGE_FORM_STEP,
                        payload: { step: steps.CREATE },
                      });
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
