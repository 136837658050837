import React, { useEffect, useMemo } from "react";
import useAlert from "../hooks/useAlert";
import { useQuery } from "@apollo/client";
import { Loading } from "../components/Loading";
import { useFetchAPI } from "../api/axios/axios";
// import { useMixPanel } from "../hooks/useMixPanel";
import banner from "../assets/images/bhp-banner.png";
import { displayLogo } from "../components/PartnerLogo";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formulateAndUpdateManifest } from "../utils/funcs";
import { GET_PARTNER_BY_SUBDOMAIN } from "../api/graphQL/query";
import { useQuery as useReactQuery } from "@tanstack/react-query";
import { useAppDispatch, useAppState } from "../contexts/appContext";
import { Button, Skeleton, Typography, useTheme } from "@mui/material";
import { appReducerTypes, hospitalSteps, steps } from "../utils/mockData";

// const whiteList = (process.env.REACT_APP_WHITELIST || "").split(" ");
const followUpFeatureWhiteList = (
  process.env.REACT_APP_FOLLOWUP_WHITELIST || ""
).split(" ");
const hospitalConsultationWhiteList = (
  process.env.REACT_APP_HOSPITAL_CONSULT_WHITELIST || ""
).split(" ");

export const SubDomain = () => {
  const theme = useTheme();
  const appState = useAppState();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { subdomain } = useParams();
  const { getErrorMsg } = useAlert();
  const { GET_PLANS } = useFetchAPI();
  const searchParams = new URLSearchParams(location.search);
  const viaParam = searchParams.get("via");
  // const { loadSubdomain } = useMixPanel();

  const { loading, error, data } = useQuery(GET_PARTNER_BY_SUBDOMAIN, {
    variables: { subdomain },
  });
  const partnerInfo = data?.getPartnerBySubdomain;

  const { widgetColor, widgetLogo, category, apiKey, providerId } =
    useMemo(() => {
      return data?.getPartnerBySubdomain || {};
    }, [data]);

  const hasHospitalConsultationFeature =
    hospitalConsultationWhiteList.includes(providerId);

  const {
    data: providerPlans,
    isLoading,
    error: plansError,
  } = useReactQuery({
    queryKey: ["providerPlans", providerId, apiKey],
    queryFn: async () => await GET_PLANS(providerId, apiKey),
    enabled: !!providerId && !!apiKey,
  });

  // ADD PARTNER INFO TO APP STATE
  useEffect(() => {
    if (!data) return;
    dispatch({
      type: appReducerTypes.ADD_PARTNER_INFO,
      payload: { partnerInfo },
    });
    dispatch({
      type: appReducerTypes.ADD_PROVIDER_FEATURES,
      payload: {
        providerFeatures: {
          hasPharmacyFeature: true, // whiteList.includes(partnerInfo?.providerId),
          hasFollowUpFeature: followUpFeatureWhiteList.includes(
            partnerInfo?.providerId
          ),
        },
      },
    });
    sessionStorage.setItem("access_token", partnerInfo?.apiKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // ADD PLANS TO APP CONTEXT WHEN IT FINISH LOADING
  useEffect(() => {
    if (!providerPlans) return;
    const plans = providerPlans?.data?.plan;
    dispatch({ type: appReducerTypes.ADD_PLANS, payload: { plans } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerPlans]);

  // SET LOADING STATE
  useEffect(() => {
    const load = Boolean(loading) || isLoading;
    if (dispatch)
      dispatch({
        type: appReducerTypes.CHANGE_LOADING_STATE,
        payload: { loading: load },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, isLoading]);

  useEffect(() => {
    const logo = widgetLogo ? widgetLogo : undefined;
    formulateAndUpdateManifest(subdomain, logo);
  }, [subdomain, widgetLogo]);

  // useEffect(() => {
  //   loadSubdomain();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.pathname, partnerInfo?.providerId]);

  const disableBtn =
    ((appState?.plans || []).length === 0 || !appState?.plans) &&
    category !== "hmo";

  if (error)
    return (
      <div className="h-screen flex justify-center items-center">
        <p className=" text-red-500 font-medium text-xl">
          Error: {getErrorMsg(error)}
        </p>
      </div>
    );

  if (plansError)
    return (
      <div className="h-screen flex justify-center items-center">
        <p className=" text-red-500 font-medium text-xl">
          Error: {plansError?.message}
        </p>
      </div>
    );

  if (appState?.loading)
    return (
      <div className="h-screen flex justify-center items-center">
        <Loading color={widgetColor} size={40} />
      </div>
    );

  return (
    <div className="min-h-screen md:h-screen w-full md:w-[70%] flex flex-col justify-center items-center p-4 mx-auto space-y-8">
      {loading ? (
        <Skeleton
          style={{ marginTop: "5%" }}
          animation="wave"
          variant="rounded"
          width={200}
          height={50}
        />
      ) : (
        displayLogo(subdomain, widgetLogo)
      )}
      <div className="m-0">
        <img src={banner} alt="" srcSet="" className="w-full m-0" />
      </div>
      <div className="text-center font-euclid">
        <h3 className=" text-[28px] md:text-[35.3403px] tracking-[-0.01em] font-bold text-[#262626] m-0">
          Talk to a Doctor
        </h3>
        <p className=" text-sm text-[#A6A6A6] mt-4">
          Experience the comfort of <br />
          receiving medical advice from your home.
          <br /> Available 24/7.
        </p>
      </div>
      <div>
        <div className="flex flex-col lg:flex-row lg:space-x-5 space-y-5 lg:space-y-0">
          {loading ? (
            <Skeleton animation="wave" width={230} height={100} />
          ) : (
            <Button
              sx={{
                background: "transparent",
                color: `color-mix(in srgb, ${widgetColor} 0%, white)`,
                borderRadius: ` 12px !important`,
                padding: {
                  lg: `${theme.spacing(1.5, 12)} !important`,
                  xs: `1rem 2rem !important`,
                  md: `1rem 3rem !important`,
                },
                fontFamily: "Euclid Circular A !important",
                fontStyle: "normal",
                fontSize: 14,
                textTransform: "none",
              }}
              variant="contained"
              disabled={disableBtn}
              onClick={() => {
                dispatch({
                  type: appReducerTypes.CHANGE_FORM_STEP,
                  payload: { step: steps.INTRO_FORM },
                });
                dispatch({
                  type: appReducerTypes.UPDATE_IS_HOSPITAL_CONSULTATION,
                  payload: { isHospitalConsultation: false },
                });
                navigate(`/${subdomain}/create-consultation`, {
                  state: { via: viaParam },
                });
              }}
              disableElevation={true}
              style={{ backgroundColor: widgetColor || "transparent" }}
            >
              Start Instant Consultation
            </Button>
          )}
          {loading ? (
            <Skeleton animation="wave" width={230} height={100} />
          ) : hasHospitalConsultationFeature ? (
            <Button
              sx={{
                background: "transparent !important",
                color: widgetColor,
                border: `1px solid ${widgetColor}`,
                borderRadius: ` 12px !important`,
                padding: {
                  lg: `${theme.spacing(1.5, 12)} !important`,
                  xs: `1rem 2rem !important`,
                  md: `1rem 3rem !important`,
                },
                fontFamily: "Euclid Circular A !important",
                fontStyle: "normal",
                fontSize: 14,
                textTransform: "none",
              }}
              variant="contained"
              disabled={disableBtn}
              onClick={() => {
                dispatch({
                  type: appReducerTypes.CHANGE_FORM_STEP,
                  payload: { step: hospitalSteps.INTRO_FORM },
                });
                dispatch({
                  type: appReducerTypes.UPDATE_IS_HOSPITAL_CONSULTATION,
                  payload: { isHospitalConsultation: true },
                });
                navigate(`/${subdomain}/hospital-consultation`);
              }}
              disableElevation={true}
            >
              Book Hospital Appointment
            </Button>
          ) : null}
        </div>
        {((appState?.plans || [])?.length === 0 || !appState?.plans) &&
          category !== "hmo" && (
            <Typography
              sx={{ color: "red", marginBottom: "1rem", fontSize: "0.9em" }}
            >
              No plan available. Please contact your provider
            </Typography>
          )}
      </div>
    </div>
  );
};
